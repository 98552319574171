import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {
        name: "",
        username: "",
        email: "",
        age: 0,
        birthdate: '1970-01-01',
        sex: "male",
        eth_address: "",
        equants: 0,
        points: {}
    },
    token: "token"
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo(state, action) {
            state.user = action.payload.user;
        },
        setAuthToken(state, action) {
            state.token = action.payload.token;
        }
    }
});

export const { setUserInfo, setAuthToken } = userSlice.actions;

export default userSlice.reducer;
