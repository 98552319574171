import React, {useState, useEffect} from 'react';
import { postData } from '../../api/postData';
import { useDispatch, useSelector } from "react-redux";
import {setUserInfo} from "../../state/user/reducer";
import { useNavigate } from 'react-router-dom';

const Main = ({setTestPage}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {user} = useSelector((state) => state.user);

  useEffect(() => {
    
  }, [])

  return (
    <>
      <div className="dash-section dashboard-page">
        <h1>Dashboard</h1>
        <div className="dashboard-page__inner">
          {/*
          <div className="dashboard-page__inner-column">
            <div className="dashboard-page__inner-column-item">
              CA = 10
            </div>
            <div className="dashboard-page__inner-column-item">
              CN = 54
            </div>
            <div className="dashboard-page__inner-column-item">
              CR = 32
            </div>
            <div className="dashboard-page__inner-column-item">
              CO = 12
            </div>
            <div className="dashboard-page__inner-column-item">
              PL = 43
            </div>
            <div className="dashboard-page__inner-column-item">
              PR = 99
            </div>
            <div className="dashboard-page__inner-column-item">
              PC = 76
            </div>
            <div className="dashboard-page__inner-column-item">
              AC = 23
            </div>
            <div className="dashboard-page__inner-column-item">
              AA = 47
            </div>
            <div className="dashboard-page__inner-column-item">
              e = 24
            </div>
          </div>
          */}
          <div className="dashboard-page__inner-info">
            <div className="circle-diagram">
              <div className="circle-diagram__inner">
                <img src="/images/dia.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-page__action">
          <button type="button" className="brand-button" onClick={() => {setTestPage(true)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_2_4)">
                <circle cx="9.58366" cy="9.58332" r="7.91667" stroke="#0B0D13" strokeWidth="2"/>
                <path d="M16.667 16.6667L18.3337 18.3333" stroke="#0B0D13" strokeWidth="2" strokeLinecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_4">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Find out who you are
          </button>
        </div>
      </div>
      <div className="dash-section dashboard-page__bottom">
        <img className="dashboard-page__bottom-img" src="/images/trio_sm.png" alt=""/>
      </div>
    </>
  );
};

export default Main;