import { useRef } from "react";
import s from "./ModalConnector.module.css";
//import {useWeb3React} from "@web3-react/core";
//import {connectors} from "../../utils/connector";
import Button from "../Button/Button";
import { hooks as metaMaskHooks, metaMask } from '../../utils/connectors/metaMask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from '../../utils/connectors/walletConnectV2'
import { CHAINS, getAddChainParameters } from '../../utils/chains'

export default function ModalConnector({visible, setVisible}, props) {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const setProvider = (type) => {
        window.localStorage.setItem("provider", type);
    };

    const rootClasses = [s.Modal];

    if (visible) {
        rootClasses.push(s.active);
    }

    const closeModal = (event) => {
        event.preventDefault();
        setVisible(false);
    };

    return (
        <div className={rootClasses.join(" ")} onClick={closeModal}>
            <div
                className={s.ModalWrapper}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.connectTitle}>
                    Connect wallet
                    <div className={s.close} onClick={closeModal}>
                        <img src="/images/closeConnectWallet.svg" alt="" />
                    </div>
                </div>
                <div className={s.ModalContent}>
                    <div id="modalContent" className={s.ModalBody}>
                        {typeof window.ethereum !== "undefined" && (
                            <>
                                {(windowSize.current[0] > 996) ? 
                                <Button
                                    onClick={(e) => {
                                        metaMask.activate(getAddChainParameters(56))
                                        setProvider("injected");
                                        closeModal(e);
                                    }}
                                    className="btn"
                                >
                                    <div className={s.buttonContainer}>
                                        <div className={s.buttonImageHolder}>
                                            <img
                                                src="/images/mm-icon.png"
                                                alt="MetaMask"
                                                className={s.buttonImage}
                                            />
                                        </div>
                                        <p className={s.buttonName}>MetaMask</p>
                                    </div>
                                </Button>
                                :
                                <Button
                                    onClick={(e) => {
                                        metaMask.activate(getAddChainParameters(56))
                                        setProvider("injected");
                                        closeModal(e);
                                    }}
                                    className="btn"
                                >
                                    <div className={s.buttonContainer}>
                                        <div className={s.buttonImageHolder}>
                                            <img
                                                src="/images/wc-icon.png"
                                                alt="Wallet Connect"
                                                className={s.buttonImage}
                                            />
                                        </div>
                                        <p className={s.buttonName}>Wallet Browser</p>
                                    </div>
                                </Button>
                                }
                            </>
                        )}
                        <Button
                            onClick={(e) => {
                                walletConnectV2.activate(56)
                                setProvider("walletConnect");
                                closeModal(e);
                            }}
                            className="btn"
                        >
                            <div className={s.buttonContainer}>
                                <div className={s.buttonImageHolder}>
                                    <img
                                        src="/images/wc-icon.png"
                                        alt="Wallet Connect"
                                        className={s.buttonImage}
                                    />
                                </div>
                                <p className={s.buttonName}>Wallet Connect</p>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
