import React from 'react';
import {useLocation} from "react-router-dom";

const DashTopBlock = ({userLogin, equants}) => {

  const location = useLocation();
  const showRelationHandler = () => {
    const isClassExist = document.querySelector(".show-relations")
    if(isClassExist){
      document.querySelector("body").classList.remove("show-relations");
    }else{
      document.querySelector("body").classList.add("show-relations");
    }
  }

  return (
    <div className="dashTopBlock">
      <div className="dashTopBlock__name">
        Hello {userLogin ? userLogin : 'Guest'}
      </div>
      <div className="dashTopBlock__equants">
        EQuants: {equants ? equants : 0}

        <div
          onClick={()=>showRelationHandler()}
          className={'relation-btn ' + (location.pathname === '/my-relations' ? 'd-none' : '')}
            >
          !!!!
        </div>
      </div>
    </div>
  );
};

export default DashTopBlock;