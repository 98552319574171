import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({children, onClick, className, loading, loadingText, ...attrs}) => {

    const onClickAction = (e) => {
      if (loading) {
        e.preventDefault();
      } else {
        return onClick(e);
      }
    }

    const classes = classNames(
        'default-btn',
        className
    );

    const Tag = attrs.href ? 'a' : 'button';

    return (
        <Tag className={classes} disabled={loading} onClick={onClickAction} {...attrs}>
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && loadingText}
          {!loading && children}
        </Tag>
    );
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string
}

Button.defaultProps = {
  children: 'Ok',
  onClick: () => {},
  className: '',
  loading: false,
  loadingText: 'Load' 
}

export default Button;