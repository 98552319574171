import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { createBrowserHistory } from "history";
import { ethers } from "ethers";
import App from './App';
import store from "./state";
import { Web3ReactProvider } from '@web3-react/core';
import { hooks as metaMaskHooks, metaMask } from './utils/connectors/metaMask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './utils/connectors/walletConnectV2'

//const connectors = [[metaMask, metahooks], [wallethooks, walletConnectV2]];
const connectors = [[metaMask, metaMaskHooks], [walletConnectV2, walletConnectV2Hooks]];
const getLibrary = provider => new ethers.providers.Web3Provider(provider);

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <Web3ReactProvider connectors={connectors} getLibrary={getLibrary}>
            <App history={history} />
        </Web3ReactProvider>
    </Provider>
  </React.StrictMode>
);