const apiRoutes = {
    ethereum: {
        signature: 'ethereum/signature',
        authenticate: 'ethereum/authenticate'
    },
    support: {
        getTickets: 'support/getTickets',
        createTicket: 'support/createTicket',
        viewTicket: 'support/viewTicket',
        replyTicket: 'support/replyTicket',
    },
    user: {
        getUser: 'user/getUser',
        setUserLanguage: 'user/setUserLanguage',
        updateUser: 'user/updateUser',
        setUserAvatar: 'user/setUserAvatar',
        addInfo: 'user/addInfo'
    },
    friend: {
        search: 'friends/search',
        getRequests: 'friends/getRequests',
        getMatch: 'friends/getMatch',
        sendRequest: 'friends/sendRequest'
    },
    chat: {
        getMessages: 'messages/getMessages',
        getTalks: 'messages/getTalks',
        sendMessage: 'messages/sendMessage',        
    },
    test: {
        getQuestions: 'test/getQuestions',
        setTest: 'test/setTest'
    }
}

export default apiRoutes