import { configureStore } from "@reduxjs/toolkit";
import user from "./user/reducer"
import token from "./token/reducer"
import userstorage from "./userstorage/reducer"
 
const store = configureStore({
    reducer: {
        user,
        token,
        userstorage
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;