import { createSlice } from "@reduxjs/toolkit";

const userStorageSlice = createSlice({
    name: 'userstorage',
    initialState: {
        address: '',
        contract: {},
        sponsor: '0x00',
        userinfo: {
            turnover: 0,
            subs: 0,
            packages: 0,
            career_level: 0,
            date: 'None',
            nft: 0
        },
        token: ''
    },
    reducers: {
        setUserStorageInfo(state, {payload: {address, contract, sponsor, userinfo, token, nft}}) {
            state.address = address;
            state.contract = contract;
            state.sponsor = sponsor;
            state.userinfo = userinfo;
            state.token = token;
            state.nft = nft;
        }
    }
})
export const {
    setUserStorageInfo
  } = userStorageSlice.actions
export default userStorageSlice.reducer;