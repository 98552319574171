import { useState, useEffect, useCallback } from "react";
import { useWeb3React } from '@web3-react/core';
import { useSelector } from "react-redux";

import AddInfo from "../pages/PreDashboard/AddInfo";
import TestPage from "../pages/PreDashboard/TestPage";
import Main from "../pages/Dashboard/Main";
import Search from "../pages/Dashboard/Search";
import Matching from "../pages/Dashboard/Matching";
import MyRelations from "../pages/Dashboard/MyRelations";
import Education from "../pages/Dashboard/Education";
import Request from "../pages/Dashboard/Request";
import Equants from "../pages/Dashboard/Equants";
import Settings from "../pages/Dashboard/Settings";
import Chat from "../pages/Dashboard/Chat";
import Personality from "../pages/Dashboard/Personality";

import DashTopBlock from "../pages/Dashboard/DashboardBlocks/DashTopBlock";
import DashLeftMenu from "../pages/Dashboard/DashboardBlocks/DashLeftMenu";
import DashRightMenu from "../pages/Dashboard/DashboardBlocks/DashRightMenu";

import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { getUserSettings } from "../api/getUserSettings";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {webSite, apiUrl} from '../constants/config';
import { postData } from "../api/postData";

export default function Dashboard(props) {
    const { connector, account, chainId, provider, active } = useWeb3React();
    const { t, i18n } = useTranslation();

    const [sponsor, setSponsor] = useState('');
    const [userName, setUserName] = useState('Martin')
    const [equants, setEquants] = useState(64652)
    const [userSettings, setUserSettings] = useState({
        customer_login: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        country: 4,
        city: "",
        avatar: "",
        isPushNots: false,
    });

    const [userSoc, setUserSoc] = useState({
        instagram: "",
        facebook: "",
        linkedin: "",
        twitter: "",
        telegram: "",
    });

    const [addInfo, setAddInfo] = useState(false);
    const [main, setMain] = useState(true);
    const [search, setSearch] = useState(false);
    const [matching, setMatching] = useState(false);
    const [relations, setMyRelations] = useState(false);
    const [education, setEducation] = useState(false);
    const [request, setRequest] = useState(false);
    const [equantsPage, setEquantsPage] = useState(false);
    const [settings, setSettings] = useState(false);
    const [chat, setChat] = useState(false);
    const [personality, setPersonality] = useState(false);
    const [testPage, setTestPage] = useState(false);

    const {user} = useSelector((state) => state.user);

    useEffect(() => {
        if (!user.sex || user.sex === "") {
            setMain(false);
            setAddInfo(true);
        } else if (user.points === null) {
            setMain(false);
            setTestPage(true);
        } else {
            if (user.avatar !== undefined && user.avatar !== null) {
                user.avatar = user.avatar.replace(
                    "public",
                    webSite + "storage"
                );
            }
            setUserSettings({
                customer_login: user.username || "",
                first_name: user.name || "",
                last_name: '',
                phone: '',
                email: user.email || "",
                country: '',
                city: '',
                avatar: user.avatar || '',
                isPushNots: false,
            });
        }
    }, [user]);

    useEffect(() => {

        if (!props.loading) {
            const getInfo = async () => {
                if (sponsor != '0x0000000000000000000000000000000000000000') {
                    localStorage.removeItem("referralLink");
                }
            }
            getInfo();
        }
    }, [props.loading]);

    useEffect(() => {
        if (active === false) {
           window.location = "/";
        }
    }, [active]);

    const logoutHandler = async (event) => {
        //localStorage.setItem("provider", undefined);
        props.disconnect();
        localStorage.clear();
        window.location = '/';
    };

    return (
        <>
        {addInfo || testPage ?  
        <>
            {addInfo  && <AddInfo loading={props.loading} />}
            {testPage && <TestPage loading={props.loading} />}
        </>
        :
      <div className="dashboard">
        <div className="container-fluid">
          <DashTopBlock userLogin={user.username} equants={user.equants}/>
          <div className="dashWrapper">
            <DashLeftMenu 
            addInfo={addInfo}
            main={main}
            search={search}
            matching={matching}
            relations={relations}
            education={education}
            request={request}
            equantsPage={equantsPage}
            settings={settings}
            chat={chat}
            personality={personality}
            testPage={testPage}
            setAddInfo={setAddInfo}
            setMain={setMain}
            setSearch={setSearch}
            setMatching={setMatching}
            setMyRelations={setMyRelations}
            setEducation={setEducation}
            setRequest={setRequest}
            setEquantsPage={setEquantsPage}
            setSettings={setSettings}
            setChat={setChat}
            setPersonality={setPersonality}
            setTestPage={setTestPage}
            />
            <div className="dashContent">
            {main && 
                <Main loading={props.loading} setTestPage={setTestPage} />      
            }
            {testPage && 
                <TestPage loading={props.loading} />      
            }
            {search && 
                <Search loading={props.loading} />      
            }
            {matching && 
                <Matching loading={props.loading} />      
            }
            {relations && 
                <MyRelations loading={props.loading} />      
            }
            {education && 
                <Education loading={props.loading} />      
            }
            {request && 
                <Request loading={props.loading} />      
            }
            {equantsPage && 
                <Equants loading={props.loading} />      
            }
            {settings && 
                <Settings loading={props.loading} />      
            }
            {chat && 
                <Chat loading={props.loading} />      
            }
            {personality && 
                <Personality loading={props.loading} />      
            }
            </div>
            <DashRightMenu userName={user.name} userLogin={user.username} equants={user.equants}/>
          </div>

        </div>
      </div>
        }
    </>
    )
}