import React from 'react';

const DashRightMenu = ({userLogin, userName, equants}) => {
  return (
    <div className="dashRightMenu">
      <div className="dashRightMenu__user">
        <div className="dashRightMenu__user-top">
          <div className="dashRightMenu__user-ava">
            <img className="ibg" src="/images/trio.png" alt=""/>
          </div>
          <div className="dashRightMenu__user-info">
            {userName}
            <span>#{userLogin}</span>
          </div>
        </div>
        <div className="dashRightMenu__user-bottom">
          EQuants: <span>{equants}</span>
        </div>
      </div>
      <div className="dashRightMenu__users">
        <h3>Relation seals</h3>
        <div className="dashRightMenu__users-inner">
          <div className="dashRightMenu__users-item">
            <div className="dashRightMenu__users-item-ava">
              <img className="ibg" src="/images/trio.png" alt=""/>
            </div>
            <div className="dashRightMenu__users-item-name">
              Davis Korsgaard
            </div>
          </div>
          <div className="dashRightMenu__users-item">
            <div className="dashRightMenu__users-item-ava">
              <img className="ibg" src="/images/trio.png" alt=""/>
            </div>
            <div className="dashRightMenu__users-item-name">
              Davis Korsgaard 123123aygdi11111
            </div>
          </div>
          <div className="dashRightMenu__users-item">
            <div className="dashRightMenu__users-item-ava">
              <img className="ibg" src="/images/trio.png" alt=""/>
            </div>
            <div className="dashRightMenu__users-item-name">
              Davis Korsgaard asdaklsgdj-asdkhasgdkasd askhdhajksdakjsh asdjhasvdas dasjdh
            </div>
          </div>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

        </div>
      </div>
    </div>
  );
};

export default DashRightMenu;