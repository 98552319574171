import React, {useState} from 'react';

const Education = () => {

  return (
            <div className="dashboard-blocks education">
              <div className="dash-section">
                <h1>Education</h1>
                <div className="education__list">
                    <div className="education__category">
                      <h4>Open communication</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="education__category">
                      <h4>Personality scan</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          {/*<img className="ibg" src="/images/trio.png" alt=""/>*/}
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                        <div className="education__category-item">
                          {/*<img className="ibg" src="/images/trio.png" alt=""/>*/}
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="education__category">
                      <h4>Charisma training</h4>
                      <div className="education__category-items">
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status --done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none">
                              <path d="M5 10.0004L8.53553 13.5359L15.6058 6.46484" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            done
                          </div>
                        </div>
                        <div className="education__category-item">
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>

                        <div className="education__category-item">
                          <img className="ibg" src="/images/trio.png" alt=""/>
                          <div className="education__category-item-status">
                            pending
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
  );
};

export default Education;