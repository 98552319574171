const BNB = {
  name: "BSC token",
  symbol: "BNB",
  decimals: 18,
};

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId];
  return {
    chainId,
    chainName: chainInformation.name,
    nativeCurrency: chainInformation.nativeCurrency,
    rpcUrls: chainInformation.urls,
    blockExplorerUrls: chainInformation.blockExplorerUrls,
  };
}

export const MAINNET_CHAINS = {
  56: {
    urls: ["https://bsc-dataseed.binance.org/"],
    name: "BSC Chain",
    nativeCurrency: BNB,
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

export const CHAINS = {
  ...MAINNET_CHAINS,
};

export const URLS = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validURLs = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
