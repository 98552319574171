import React, {useEffect, useState} from 'react';
import MyTopNav from "../Temp/MyTopNav";
import TestPageQuestion from "./TestPageInc/TestPageQuestion";
import { postData } from '../../api/postData';
import {useNavigate} from "react-router-dom";

const TestPage = () => {
  const [isStartBlock, setIsStartBlock] = useState(true)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [userAnswers, setUserAnswers] = useState([])
  const navigate = useNavigate()

  const startTestingHandler = () => {
    setIsStartBlock(false)
    setCurrentQuestion(1)
  }

  useEffect(() => {
    getQuestions();
  }, [])

  const getQuestions = async () => {
    postData('test.getQuestions', {}).then((res) => {
      if (res.status === "success") {
        const formattedQuestions = res.data.map((item) => ({
          id: item.id,
          question: item.main, // Use the 'main' text as the question
          answers: item.answers.map((answer, index) => ({
            id: `${item.id}${index}`,
            answer,
            selected: false, // Initialize 'selected' state
          })),
        }));
        setTestArray(formattedQuestions);
      }
    });
  }

  const [testArray, setTestArray] = useState([
    {
      id: 1,
      question: 'Wait',
      answers: [
        {id: 11, answer: '1', selected: false},
        {id: 22, answer: '2', selected: false},
        {id: 33, answer: '3', selected: false},
      ]
    }
  ])

  const setAnswerHandler = (q, a) => {
    console.log(q)
    console.log(a)
  }

  const updateQuestionsArrayHandler = (questionId, answerId) => {

    const questionIdx = testArray.findIndex(el => el.id === questionId)
    if(questionIdx > -1){
      const answerIdx = testArray[questionIdx].answers.findIndex(ele => ele.id === answerId)
      if(answerIdx > -1){
        const updatedTestArray = [...testArray];

        updatedTestArray[questionIdx].answers.map(a => {
          a.selected = false
        })

        updatedTestArray[questionIdx].answers[answerIdx].selected = true
        setTestArray(updatedTestArray);
      }
    }
    
    setUserAnswers((prevUserAnswers) => {
      const updatedAnswers = [...prevUserAnswers];
      const existingAnswerIdx = updatedAnswers.findIndex((ans) => ans[0] === questionId);

      if (existingAnswerIdx > -1) {
        // Replace the existing answer for this question
        updatedAnswers[existingAnswerIdx] = [questionId, answerId];
      } else {
        // Add a new answer for this question
        updatedAnswers.push([questionId, answerId]);
      }

      return updatedAnswers;
    });
  }

  const nextQuestionHandler = (qId) => {
    if(testArray.length >= qId + 1) {
      setCurrentQuestion(qId + 1)
    }else{
      finishTestingHandler()
    }
  }

  const finishTestingHandler = async () => {
    try {
      const response = await postData('test.setTest', {answers: userAnswers});
  
      if (response?.status === 'success') {
        console.log("User information submitted successfully.");
        window.location = '/dashboard';
      } else {
        console.error("Submission failed:", response?.message || "Unknown error");
        alert("An error occurred while submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error during submission:", error.message);
      alert("A network or server error occurred. Please check your connection.");
    }
  }

  return (
    <>
      <div className="preDashboard testPage">
        {isStartBlock &&
          <div className="testPage__block testPage__start">
            <img src="/images/trio.png" alt="" className="ibg"/>
            <div className="testPage__start-bottom">
              <button
                type="button"
                className="brand-button"
                onClick={() => startTestingHandler()}
              >
                Make a test
              </button>
            </div>
          </div>
        }
        {!isStartBlock &&
          <div className="testPage__block testPage__question">
            {testArray.map(question =>
              (
                <div key={question.id}>
                  {currentQuestion === question.id &&
                    <TestPageQuestion
                      question={question}
                      updateQuestionsArray={updateQuestionsArrayHandler}
                      nextQuestionHandler={nextQuestionHandler}
                    />
                  }
                </div>
              )
            )}
          </div>
        }
      </div>
    </>
  );
};

export default TestPage;