import React, { useState } from 'react';
import MyTopNav from "../Temp/MyTopNav";
import ModalConnector from '../../components/ModalConnector/ModalConnector';
import {useNavigate} from "react-router-dom";
import { useWeb3React } from '@web3-react/core';

const ConnectWallet = ({loading}) => {
  const { connector, account, chainId, provider, active } = useWeb3React();
  const [openWallet, setOpenWallet] = useState(false);
  const navigate = useNavigate()

  const connectWalletHandler = () => {
    setOpenWallet(true)
  }

  return (
    <>
      <MyTopNav />
      <div className="preDashboard connectWallet">
        <div className="connectWallet__block">
          {loading ? 
          <>
          <h1>Connect wallet</h1>
          <span className="connectWallet__title-description">Connect your wallet</span>
          <button
            className="brand-button"
            onClick={() => connectWalletHandler()}
          >
            Connect wallet
          </button>
          <span className="connectWallet__bottom-description">Hiht what wallet connect</span>
          </>
          :
            <>
          <h1>Dashboard</h1>
          <span className="connectWallet__title-description">Enter into dashboard</span>
          <button
            className="brand-button"
            onClick={() => navigate('/dashboard')}
          >
            Go
          </button>
          <span className="connectWallet__bottom-description">Hiht what wallet connect</span>
            </>
          }
        </div>
      </div>
      {openWallet &&
        <ModalConnector visible={openWallet} setVisible={setOpenWallet} />
      }
    </>
  );
};

export default ConnectWallet;