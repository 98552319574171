import { apiUrl } from "../constants/config";
import apiRoutes from "../api/routes"
import store from "../state/";

const resolveRoute = (route) => {
    const keys = route.split('.'); // Split the route string (e.g., 'user.getUser')
    let resolvedRoute = apiRoutes;

    for (const key of keys) {
        resolvedRoute = resolvedRoute[key];
        if (!resolvedRoute) {
            throw new Error(`Invalid route: ${route}`);
        }
    }

    return resolvedRoute;
};

//export const postData = async (route, data, raw_auth) => {
export const postData = async (route, data, token) => {

    try {
        /*let body = { raw_auth: raw_auth };
    
        if (Object.keys(data).length > 0) {
            //body.data = data;
            body = Object.assign({}, body, data)
        }*/
        if (!token) {
            const userState = store.getState().user;
            token = userState?.token || "";
        }

        const resolvedRoute = resolveRoute(route);

        const res = await fetch(apiUrl + resolvedRoute , {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*'
            },
            //body: JSON.stringify(body)
            body: JSON.stringify(data)
        });

        return await res.json();
    } catch (e) {
        console.log("Error fetching rate:", e);
    } finally {
        
    }
}

export const login = async (auth) => {
    const res = await fetch(apiUrl + resolveRoute('auth.login'), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*'
        },
        body: JSON.stringify({auth: auth})
    });

    const response = await res.json();

    //dispatch(setAuthToken(response?.data.token))

    return response?.token;
}