import './css/bootstrap.css'
import './css/reset.css'
import './css/style.css'

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import { useEffect, useState, Suspense } from "react";
import { ethers } from "ethers";
import { useWeb3React } from '@web3-react/core';
import { hooks as metaMaskHooks, metaMask } from './utils/connectors/metaMask'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './utils/connectors/walletConnectV2'
import { useDispatch } from "react-redux";
import {setAuthToken, setUserInfo} from "./state/user/reducer";
import Loading from "./components/Loading";
import "./utils/i18n";
import USDTBEP20 from "./abi/USDTBEP20";
import QLG from "./abi/QLG";

import ConnectWallet from "./pages/PreDashboard/ConnectWallet";
import AddInfo from "./pages/PreDashboard/AddInfo";
import TestPage from "./pages/PreDashboard/TestPage";
import Dashboard from './layouts/Dashboard';
import axios from 'axios';
import { apiUrl } from './constants/config';
import swal from 'sweetalert';
import { postData } from './api/postData';

function App() {
	const { connector, account, chainId, provider, active } = useWeb3React();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const savedprovider = window.localStorage.getItem("provider");
    if (savedprovider) {
        if (savedprovider == 'injected') {
            metaMask.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to metamask')
            })
        } else if (savedprovider == 'walletConnect') {
            walletConnectV2.connectEagerly().catch((error) => {
                console.debug('Failed to connect eagerly to walletconnect', error)
              })
        }/* else {
            if (connector?.deactivate) {
            connector.deactivate()
            } else {
            connector.resetState()
            }
        }*/
    }
    if (provider) {
        loadBlockchainData();
    }
  }, []);

  useEffect(() => {
    if (provider) {
        switchEffect();
        loadBlockchainData();
    }
  }, [provider, chainId, account]);

  const switchEffect = async () => {
    await switchNetwork();
  };

  const loadBlockchainData = async () => {
    try {
      const signer = provider.getSigner();
      const networkId = chainId;

      const tokenData = USDTBEP20.networks[networkId];

      const QLGData = QLG.networks[networkId];

      if (QLGData) {
        let qlgContract = new ethers.Contract(
          QLGData.address,
          QLG.abi,
          signer
        )

        let qlgAmount = await qlgContract.balanceOf(account);

        if (qlgAmount > 0) {
          let authToken = localStorage.getItem("signtoken");
          if (authToken) {
            dispatch(setAuthToken({ token: authToken }))
          } else {
            await authSign();
          }

          postData('user.getUser', {}).then((res) => {
            if (res?.data) {
              dispatch(setUserInfo({ user: res.data }))
            }
          });
          
          setLoading(false);
        } else {
          swal('Not Enough QLG');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const authSign = async () => {
    try {
      const message = (
        await axios.post(apiUrl + "ethereum/signature", {
          address: account,
        })
      ).data;
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);

      try {
        let token = (
          await axios.post(apiUrl + "ethereum/authenticate", {
            address: account,
            signature: signature,
          })
        ).data;

        localStorage.setItem("signtoken", token);
        dispatch(setAuthToken({ token: token }))

        swal("Successfully Signed");
      } catch (e) {
        alert(e.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  const switchNetwork = async () => {
    const providerType = window.localStorage.getItem("provider");

    if (providerType === "injected" && window.ethereum) {
        const chainHex = "0x38"; // Chain ID for Binance Smart Chain Mainnet
        //const chainHex = "0x61"; // Chain ID for Binance Smart Chain Testnet

        try {
            // Attempt to switch the network
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainHex }],
            });
        } catch (switchError) {
            console.error("Error switching network:", switchError);

            // If the network is not added to the wallet, add it
            if (switchError.code === 4902 || switchError.code === -32603) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Binance Smart Chain Testnet",
                                chainId: chainHex,
                                nativeCurrency: {
                                    name: "BNB",
                                    decimals: 18,
                                    symbol: "BNB",
                                },
                                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                                blockExplorerUrls: ["https://testnet.bscscan.com/"],
                            },
                        ],
                    });
                } catch (addError) {
                    console.error("Error adding network:", addError);
                    window.alert("Failed to add the network. Please try manually.");
                }
            } else {
                window.alert("Failed to switch the network. Please try manually.");
            }
        }
    } else {
        window.alert("Injected provider not found. Please install MetaMask.");
    }
  };

  const disconnect = () => {
    if (connector?.deactivate) {
        connector.deactivate()
    } else {
        connector.resetState()
    }
    setLoading(true);
  };
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ConnectWallet loading={loading} />,
    },
    {
      path: "/dashboard",
      element: <Dashboard loading={loading} disconnect={disconnect} />,
    },
    {
      path: "/add-info",
      element: <AddInfo />,
    },
    {
      path: "/test",
      element: <TestPage />,
    },
    /*{
      path: "/dashboard",
      element: <Main />,
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/matching",
      element: <Matching />,
    },
    {
      path: "/my-relations",
      element: <MyRelations />,
    },
    {
      path: "/education",
      element: <Education />,
    },
    {
      path: "/request",
      element: <Request />,
    },
    {
      path: "/equants",
      element: <Equants />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
    {
      path: "/personality",
      element: <Personality />,
    },*/

  ]);

  return (
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
  );
}

export default App;
