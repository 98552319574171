import React, {useMemo, useState, useEffect} from 'react';
import Avatar from "react-avatar-edit";
import { postData } from '../../api/postData';
import { useSelector } from 'react-redux';

const Settings = () => {

  const {user} = useSelector((state) => state.user);

  const [userInfo, setUserInfo] = useState({
    name: '',
    username: '',
    created_at: '',
    email: '',
    sex: '',
    age: '',
    birthdate: ''
  })

  useEffect(() => {
    if (user) {
      setUserInfo({
        name: user.name || '',
        username: user.username || '',
        email: user.email || '',
        created_at: user.created_at || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserInfo(userInfo => ({
      ...userInfo,
      [name]: value
    }));
  }

  const submitHandler = (e) => {
    e.preventDefault()
    postData('user.updateUser', userInfo).then((res) => {

    });
  }


  const onClose = () => {
    setSrc(null);
    setPreview(null);
  }

  const onCrop = (preview) => {
    setPreview(preview)
  }

  const onBeforeFileLoad = (elem) => {
    console.log('onBeforeFileLoad')
  }

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result);
      };

      setImageFile(file);
      reader.readAsDataURL(file);
    }
  };

  const removeAvatarDataHandler = () => {
    setSrc(null)
    setPreview(null)
    setImageFile(null)
  }

  return (
    <>
              <div className="dashboard-blocks dashboard-settings">
                <div className="dash-section dashboard-settings-result">
                  <h1>Settings</h1>
                  <div className="dash-section dashboard-settings-result-inner">
                    <form className="dashboard-settings__form">
                    <div className="">
                      <input type="file" id="avatar__input" onChange={handleFileChange} />
                      {src && (
                        <div className="dashboard-settings__form-avatar-holder ">
                          <Avatar
                            src={src}
                            width={300}
                            height={195}
                            onCrop={(e) => onCrop(e)}
                            onClose={() => onClose()}
                            onBeforeFileLoad={() => onBeforeFileLoad()}
                            label="Add avatar"
                            labelStyle={{color:'#F3F7FF',fontSize:18, fontWeight:700}}
                            borderStyle={{cursor:'pointer'}}
                          />
                        </div>
                      )}
                        <div className="avatar_block">
                          <label htmlFor="avatar__input" className="dashboard-settings__form-avatar-preview">
                            {preview
                              ?
                              <img src={preview} alt="Preview" />
                              :
                              <div className="no-photo-holder">
                                <img className="no-photo ibg" src='/images/trio.png' alt="Preview" />
                              </div>
                            }
                          </label>
                          <div className="dashboard-settings__user-info">
                            <div className="dashboard-settings__user-info-name">
                              {userInfo.name ? userInfo.name : ''}
                            </div>
                            <div className="dashboard-settings__user-info-hash">
                              #{userInfo.username ? userInfo.username : '-----'}
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Name
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="text"
                        name="name"
                        placeholder="Name"
                        required={true}
                        value={userInfo.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Username / login
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="text"
                        placeholder="Username / login"
                        required={true}
                        value={userInfo.username}
                        name="username"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="dashboard-settings__form-control">
                      <div className="dashboard-settings__form-control-label">
                        Email
                      </div>
                      <input
                        className="dashboard-settings__form-input"
                        type="email"
                        placeholder="example@example.com"
                        required={true}
                        value={userInfo.email}
                        name="email"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Sex
              </div>
              <select 
                name="sex"
                required={true}
                className="addInfo__form-input"
                value={userInfo.sex}
                onChange={(e) => handleChange(e)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Age
              </div>
              <input
                className="addInfo__form-input"
                type="text"
                placeholder="Age"
                required={true}
                value={userInfo.age}
                name="age"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="addInfo__form-control">
              <div className="addInfo__form-control-label">
                Birth date
              </div>
              <input
                className="addInfo__form-input"
                type="date"
                placeholder="01.01.1990"
                required={true}
                value={userInfo.birthdate}
                name="birthdate"
                onChange={(e) => handleChange(e)}
              />
            </div>

                    <button
                      className="brand-button"
                      onClick={(e) => submitHandler(e)}
                    >
                      Save
                    </button>
                  </form>
                  </div>
                </div>

                {/*<div className="dash-section trio-block">*/}
                {/*  <img className="dashboard-page__bottom-img" src="/images/trio_sm.png" alt=""/>*/}
                {/*</div>*/}
              </div>
    </>
  );
};

export default Settings;