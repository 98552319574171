import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loading() {

  const pachLoader = {
      width:"100%",
      height:"100vh",
      display: "flex",
      justifyContent:'center',
      alignItems:'center'
  }

  return (
    <div style={pachLoader}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}