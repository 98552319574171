import React from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Search from "../Search";
import Main from "../Main";

const DashLeftMenu = ({
  addInfo,
  main,
  search,
  matching,
  relations,
  education,
  request,
  equantsPage,
  settings,
  chat,
  personality,
  testPage,
  setAddInfo,
  setMain,
  setSearch,
  setMatching,
  setMyRelations,
  setEducation,
  setRequest,
  setEquantsPage,
  setSettings,
  setChat,
  setPersonality,
  setTestPage
}) => {

  const navigate = useNavigate()
  const location = useLocation();

  const goToPage = (page) => {
    setAddInfo(false);
    setMain(false);
    setSearch(false);
    setMatching(false);
    setMyRelations(false);
    setEducation(false);
    setRequest(false);
    setEquantsPage(false);
    setSettings(false);
    setChat(false);
    setPersonality(false);
    setTestPage(false);

    if (page === 'dashboard') {
      setMain(true);
    } else if (page === 'search') {
      setSearch(true)
    } else if (page === 'matching') {
      setMatching(true)
    } else if (page === 'chat') {
      setChat(true)
    } else if (page === 'education') {
      setEducation(true)
    } else if (page === 'personality') {
      setPersonality(true)
    } else if (page === 'settings') {
      setSettings(true)
    } else if (page === 'request') {
      setRequest(true)
    } else if (page === 'equants') {
      setEquantsPage(true)
    }
  }

  return (
    <div className="dashLeftMenu left-menu">
      <div className="dashLeftMenu__inner">
        <div
          onClick={() => goToPage('dashboard')}
          className={'left-menu__item ' + (main ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path d="M6.4443 3.6853C6.97115 3.33327 7.52766 3.03383 8.10577 2.7894C9.50868 2.19627 10.2101 1.8997 11.1051 2.49296C12 3.08623 12 4.05748 12 6V8C12 9.88561 12 10.8284 12.5858 11.4142C13.1716 12 14.1144 12 16 12H18C19.9425 12 20.9138 12 21.507 12.8949C22.1003 13.7899 21.8037 14.4913 21.2106 15.8942C20.9662 16.4723 20.6667 17.0288 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8078C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17316C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853Z" fill=""/>
              <path fillRule="evenodd" clipRule="evenodd" d="M13.7739 2.12806C13.8771 1.72692 14.286 1.48543 14.6871 1.58867C18.4658 2.56123 21.4389 5.53437 22.4115 9.31301C22.5147 9.71415 22.2732 10.123 21.8721 10.2263C21.4709 10.3295 21.0621 10.088 20.9588 9.6869C20.1225 6.43751 17.5626 3.87766 14.3132 3.04133C13.9121 2.93808 13.6706 2.5292 13.7739 2.12806Z" fill=""/>
            </svg>
            <span>Dashboard</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('search')}
          className={'left-menu__item ' + (search ? 'left-menu__item-active' : '') }
        >
          <div
            onClick={() => goToPage('search')}
            className="left-menu__link "
          >
            <svg className="left-menu__icon-stroke" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <g clipPath="url(#clip0_2_626)">
                <circle cx="11.5" cy="11.5" r="9.5" stroke="" strokeWidth="3"/>
                <path d="M20 20L22 22" stroke="" strokeWidth="3" strokeLinecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2_626">
                  <rect width="24" height="24" fill=""/>
                </clipPath>
              </defs>
            </svg>
            <span>Search</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('matching')}
          className={'left-menu__item ' + (matching ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z" fill=""/>
              <path d="M18 16.5C18 18.433 15.3137 20 12 20C8.68629 20 6 18.433 6 16.5C6 14.567 8.68629 13 12 13C15.3137 13 18 14.567 18 16.5Z" fill=""/>
              <path d="M7.12205 5C7.29951 5 7.47276 5.01741 7.64005 5.05056C7.23249 5.77446 7 6.61008 7 7.5C7 8.36825 7.22131 9.18482 7.61059 9.89636C7.45245 9.92583 7.28912 9.94126 7.12205 9.94126C5.70763 9.94126 4.56102 8.83512 4.56102 7.47063C4.56102 6.10614 5.70763 5 7.12205 5Z" fill=""/>
              <path d="M5.44734 18.986C4.87942 18.3071 4.5 17.474 4.5 16.5C4.5 15.5558 4.85657 14.744 5.39578 14.0767C3.4911 14.2245 2 15.2662 2 16.5294C2 17.8044 3.5173 18.8538 5.44734 18.986Z" fill=""/>
              <path d="M16.9994 7.5C16.9994 8.36825 16.7781 9.18482 16.3888 9.89636C16.547 9.92583 16.7103 9.94126 16.8774 9.94126C18.2918 9.94126 19.4384 8.83512 19.4384 7.47063C19.4384 6.10614 18.2918 5 16.8774 5C16.6999 5 16.5267 5.01741 16.3594 5.05056C16.7669 5.77446 16.9994 6.61008 16.9994 7.5Z" fill=""/>
              <path d="M18.5521 18.986C20.4821 18.8538 21.9994 17.8044 21.9994 16.5294C21.9994 15.2662 20.5083 14.2245 18.6036 14.0767C19.1429 14.744 19.4994 15.5558 19.4994 16.5C19.4994 17.474 19.12 18.3071 18.5521 18.986Z" fill=""/>
            </svg>
            <span>Matching</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('chat')}
          className={'left-menu__item ' + (chat ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path fillRule="evenodd" clipRule="evenodd" d="M18 14C18 18.4183 14.4183 22 10 22C8.76449 22 7.5944 21.7199 6.54976 21.2198C6.19071 21.0479 5.78393 20.9876 5.39939 21.0904L4.17335 21.4185C3.20701 21.677 2.32295 20.793 2.58151 19.8267L2.90955 18.6006C3.01245 18.2161 2.95209 17.8093 2.7802 17.4502C2.28008 16.4056 2 15.2355 2 14C2 9.58172 5.58172 6 10 6C14.4183 6 18 9.58172 18 14ZM6.5 15C7.05228 15 7.5 14.5523 7.5 14C7.5 13.4477 7.05228 13 6.5 13C5.94772 13 5.5 13.4477 5.5 14C5.5 14.5523 5.94772 15 6.5 15ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15ZM13.5 15C14.0523 15 14.5 14.5523 14.5 14C14.5 13.4477 14.0523 13 13.5 13C12.9477 13 12.5 13.4477 12.5 14C12.5 14.5523 12.9477 15 13.5 15Z" fill=""/>
              <path opacity="0.6" d="M17.9838 14.5084C18.0917 14.4638 18.1981 14.4163 18.303 14.3661C18.5947 14.2264 18.9252 14.1774 19.2377 14.261L20.2338 14.5275C21.019 14.7376 21.7373 14.0193 21.5272 13.2342L21.2606 12.238C21.177 11.9256 21.2261 11.595 21.3657 11.3033C21.7721 10.4545 21.9997 9.50385 21.9997 8.5C21.9997 4.91015 19.0895 2 15.4997 2C12.7896 2 10.4669 3.6585 9.49121 6.0159C9.65934 6.00535 9.82887 6 9.99965 6C14.4179 6 17.9997 9.58172 17.9997 14C17.9997 14.1708 17.9943 14.3403 17.9838 14.5084Z" fill=""/>
            </svg>
            <span>Chat</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('education')}
          className={'left-menu__item ' + (education ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path d="M14.217 3.49965C12.796 2.83345 11.2035 2.83345 9.78252 3.49965L5.48919 5.51246C6.27114 5.59683 6.98602 6.0894 7.31789 6.86377C7.80739 8.00594 7.2783 9.32867 6.13613 9.81817L5.06046 10.2792C4.52594 10.5082 4.22261 10.6406 4.01782 10.7456C4.0167 10.7619 4.01564 10.7788 4.01465 10.7962L9.78261 13.5003C11.2036 14.1665 12.7961 14.1665 14.2171 13.5003L20.9082 10.3634C22.3637 9.68105 22.3637 7.31899 20.9082 6.63664L14.217 3.49965Z" fill=""/>
              <path d="M4.9998 12.9147V16.6254C4.9998 17.6334 5.50331 18.5772 6.38514 19.0656C7.85351 19.8787 10.2038 21 11.9998 21C13.7958 21 16.1461 19.8787 17.6145 19.0656C18.4963 18.5772 18.9998 17.6334 18.9998 16.6254V12.9148L14.8538 14.8585C13.0294 15.7138 10.9703 15.7138 9.14588 14.8585L4.9998 12.9147Z" fill=""/>
              <path d="M5.54544 8.43955C5.92616 8.27638 6.10253 7.83547 5.93936 7.45475C5.7762 7.07403 5.33529 6.89767 4.95456 7.06083L3.84318 7.53714C3.28571 7.77603 2.81328 7.97849 2.44254 8.18705C2.04805 8.40898 1.70851 8.66944 1.45419 9.05513C1.19986 9.44083 1.09421 9.85551 1.04563 10.3055C0.999964 10.7284 0.999981 11.2424 1 11.8489V14.7502C1 15.1644 1.33579 15.5002 1.75 15.5002C2.16422 15.5002 2.5 15.1644 2.5 14.7502V11.8878C2.5 11.232 2.50101 10.7995 2.53696 10.4665C2.57095 10.1517 2.63046 9.99612 2.70645 9.88087C2.78244 9.76562 2.90202 9.64964 3.178 9.49438C3.46985 9.33019 3.867 9.15889 4.46976 8.90056L5.54544 8.43955Z" fill=""/>
            </svg>
            <span>Education</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('personality')}
          className={'left-menu__item ' + (personality ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <circle cx="12" cy="6" r="4" fill=""/>
              <ellipse cx="12" cy="17" rx="7" ry="4" fill=""/>
            </svg>
            <span>Personality</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('settings')}
          className={'left-menu__item ' + (settings ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.4277 2C11.3139 2 10.2995 2.6007 8.27081 3.80211L7.58466 4.20846C5.55594 5.40987 4.54158 6.01057 3.98466 7C3.42773 7.98943 3.42773 9.19084 3.42773 11.5937V12.4063C3.42773 14.8092 3.42773 16.0106 3.98466 17C4.54158 17.9894 5.55594 18.5901 7.58466 19.7915L8.27081 20.1979C10.2995 21.3993 11.3139 22 12.4277 22C13.5416 22 14.5559 21.3993 16.5847 20.1979L17.2708 19.7915C19.2995 18.5901 20.3139 17.9894 20.8708 17C21.4277 16.0106 21.4277 14.8092 21.4277 12.4063V11.5937C21.4277 9.19084 21.4277 7.98943 20.8708 7C20.3139 6.01057 19.2995 5.40987 17.2708 4.20846L16.5847 3.80211C14.5559 2.6007 13.5416 2 12.4277 2ZM8.67773 12C8.67773 9.92893 10.3567 8.25 12.4277 8.25C14.4988 8.25 16.1777 9.92893 16.1777 12C16.1777 14.0711 14.4988 15.75 12.4277 15.75C10.3567 15.75 8.67773 14.0711 8.67773 12Z" fill=""/>
            </svg>
            <span>Settings</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('request')}
          className={'left-menu__item ' + (request ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM5 8.49982V7.5C5 6.67157 5.67157 6 6.5 6H17.5C18.3284 6 19 6.67157 19 7.5V8.49982C18.5822 8.18597 18.0628 8 17.5 8H6.5C5.9372 8 5.41783 8.18597 5 8.49982ZM5 10.5V11.4998C5.41783 11.186 5.9372 11 6.5 11H17.5C18.0628 11 18.5822 11.186 19 11.4998V10.5C19 9.67157 18.3284 9 17.5 9H6.5C5.67157 9 5 9.67157 5 10.5ZM20 14.2502C20.4142 14.2502 20.75 14.5859 20.75 15.0002C20.75 15.4144 20.4142 15.7502 20 15.7502H17.6569C16.7782 15.7502 16.5477 15.7634 16.3501 15.8453C16.1524 15.9272 15.9801 16.0807 15.3588 16.7021L15.0543 17.0066C14.6223 17.4393 14.2811 17.7811 13.8467 17.9864C13.7658 18.0247 13.683 18.059 13.5987 18.0892C13.1464 18.2511 12.6634 18.2507 12.052 18.2502L11.9462 18.2502L11.834 18.2502C11.1877 18.2508 10.6771 18.2512 10.2035 18.0718C10.1153 18.0384 10.0289 18.0005 9.94463 17.9582C9.49191 17.7311 9.14654 17.3551 8.70937 16.879L8.59805 16.7579C8.00971 16.1184 7.84554 15.9591 7.65508 15.8694C7.63492 15.8599 7.6145 15.8509 7.59386 15.8425C7.39887 15.7631 7.17044 15.7502 6.30147 15.7502H4C3.58579 15.7502 3.25 15.4144 3.25 15.0002C3.25 14.5859 3.58579 14.2502 4 14.2502L5.02051 14.2501V13.2503C5.13939 12.5407 5.75646 12 6.49983 12H17.4998C18.2432 12 18.8603 12.5407 18.9791 13.2503V14.2501L20 14.2502Z" fill=""/>
            </svg>
            <span>Request</span>
          </div>
        </div>
        <div
          onClick={() => goToPage('equants')}
          className={'left-menu__item ' + (equantsPage ? 'left-menu__item-active' : '') }
        >
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path fillRule="evenodd" clipRule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12C11.25 12.1989 11.329 12.3897 11.4697 12.5303L13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L12.75 11.6893V8Z" fill=""/>
            </svg>
            <span>Equants</span>
          </div>
        </div>
        <div className="left-menu__item ">
          <div className="left-menu__link ">
            <svg  className="left-menu__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167V11.9914C21 17.6294 16.761 20.3655 14.1014 21.5273C13.38 21.8424 13.0193 22 12 22C10.9807 22 10.62 21.8424 9.89856 21.5273C7.23896 20.3655 3 17.6294 3 11.9914V10.4167ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9ZM12 17C16 17 16 16.1046 16 15C16 13.8954 14.2091 13 12 13C9.79086 13 8 13.8954 8 15C8 16.1046 8 17 12 17Z" fill=""/>
            </svg>
            <span>KYC</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashLeftMenu;